<template>
  <v-navigation-drawer
    v-if="$vuetify.breakpoint.width < 1142"
    app
    v-model="drawerLocal"
  >
    <v-tabs
      v-model="tabValue"
      vertical
      style="margin-top: 10%; margin-left: 2%"
      slider-color="info"
      color="info"
      background-color="transparent"
      class="mb-12"
    >
      <v-tab v-for="item of opciones" :key="item.id" @click="goTo(item.path)">
        {{ item.name }}
      </v-tab>
    </v-tabs>

    <v-row align="start" justify="center" no-gutters class="mt-12">
      <v-col cols="6" v-for="(icon, index) of icons" :key="index" class="pa-2">
        <v-row align="center" justify="center" no-gutters>
          <v-img
            :src="icon.asset"
            contain
            max-width="30"
            @click="goToOtherPag(icon.link)"
            class="shadow"
            style="cursor: pointer"
          ></v-img>
        </v-row>
      </v-col>
    </v-row>
  </v-navigation-drawer>
</template>

<script>
import {mapMutations, mapState} from 'vuex';

export default {
  name: 'Drawer',
  data: () => ({
    drawerLocal: false,
    tabValue: 0,
    icons: [
      {
        asset: require('@/assets/logos/whatsapp.svg'),
        link:
          'https://wa.me/56944436444?text=Hola%20Automotriz%20Petersen,%20quisiera%20contactarme%20con%20un%20vendedor',
      },
      {
        asset: require('@/assets/logos/instagram.svg'),
        link: 'http://instragram.com/automotrizpetersen',
      },
      {
        asset: require('@/assets/logos/facebook.svg'),
        link: 'https://www.facebook.com/automotrizpetersen',
      },
      {
        asset: require('@/assets/logos/map.svg'),
        link:
          'https://www.google.com/maps/search/?api=1&query=automotriz%20petersen',
      },
      {
        asset: require('@/assets/logos/phone.svg'),
        link: 'tel:+569 4443 6444',
      },
    ],
    opciones: [
      {
        id: 0,
        path: 'petersen',
        name: 'Inicio',
      },
      {
        id: 1,
        path: 'compramos-tu-auto',
        name: 'Compramos tu auto',
      },
      {
        id: 2,
        path: 'vendemos-tu-auto',
        name: 'Vendemos tu auto',
      },
      {
        id: 3,
        path: 'renovacion',
        name: 'Renovación',
      },
      {
        id: 4,
        path: 'Stock',
        name: 'Stock',
      },
      {
        id: 5,
        path: 'financiamiento',
        name: 'Financiamiento',
      },
      {
        id: 6,
        path: 'testimonios',
        name: 'Testimonios',
      },
      {
        id: 7,
        path: 'mision-vision',
        name: 'Misión & visión',
      },
    ],
  }),
  methods: {
    ...mapMutations(['SET_DRAWER']),
    goTo(link) {
      this.$router.push({name: link});
    },
    goToOtherPag(link) {
      window.open(link, '_blank');
    },
  },
  computed: {
    ...mapState(['drawer']),
  },
  watch: {
    drawer: {
      immediate: true,
      handler(val) {
        if (val) this.drawerLocal = val;
      },
    },
    drawerLocal: {
      immediate: true,
      handler(val) {
        if (!val) this.SET_DRAWER(val);
      },
    },
  },
};
</script>

<style scoped></style>
